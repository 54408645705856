
import { createOutline } from "ionicons/icons";

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonPage,
  IonReorder,
  IonReorderGroup,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

import { computed, defineComponent, ref, watchEffect } from "vue";
import { Field } from "vee-validate";
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import { Activity } from "@/store/models";
import {
  deleteResourceConfirm,
  showAddActivity,
  showDiversPrompt,
} from "@/services/modals";

export default defineComponent({
  name: "Resource",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonButton,
    IonButtons,
    IonText,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonInput,
    IonReorder,
    IonReorderGroup,
    IonItem,
    IonCard,
    IonList,
    IonListHeader,
    IonIcon,
    Field,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isEditMode = ref(false);

    function toggleEdit() {
      isEditMode.value = !isEditMode.value;
    }

    const resourceName = ref("");

    const resource = computed(() => {
      store.dispatch("setCurrentResourceId", route.params.id);
      return (
        store.state.resources.find((r) => r.id === route.params.id) || {
          id: "",
          name: "",
          activities: [],
        }
      );
    });

    // update localVariable with store data;
    watchEffect(() => {
      resourceName.value = resource.value.name;
    });

    function updateResourceName(valid: boolean) {
      if (valid) {
        const updatedResource = Object.assign({}, resource.value, {
          name: resourceName.value,
        });
        store.dispatch("updateResource", updatedResource);
      }
    }

    const names = computed(() => {
      return store.getters.resourceNames.filter(
        (name: string) => name !== resource.value.name
      );
    });

    function doReorder(event: CustomEvent) {
      const updatedResource = Object.assign({}, resource.value);
      updatedResource.activities = event.detail.complete(
        resource.value.activities
      );
      store.dispatch("updateResource", updatedResource);
    }

    return {
      createOutline,
      isEditMode,
      names,
      resource,
      resourceName,
      updateResourceName,
      deleteResource() {
        deleteResourceConfirm(resource.value);
      },
      toggleEdit,
      doReorder,
      logActivity(activity: Activity, event: any) {
        const button = event.target.nodeName === "ION-BUTTON" ? event.target : event.target.parentNode;
        button.classList.add('animate');
        setTimeout(() => {
          button.classList.remove('animate');
        }, 300);
        store.dispatch("logActivity", { resource: resource.value, activity });
      },
      showDiversPrompt() {
        showDiversPrompt(resource.value);
      },
      showAddActivity,
    };
  },
});

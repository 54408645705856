<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-buttons slot="end" v-if="!$store.state.user.locked">
          <ion-button color="primary" @click="toggleEdit()">
            {{ isEditMode ? "Retour" : "Modifier" }}
          </ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center">
          <img
            src="assets/icon/logo.png"
            style="height: 40px"
            alt="xtenso|xsample"
          />
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" class="ion-padding" v-if="resource.id">
      <h2>
        {{ resource.name }}
        <span v-if="isEditMode">
          -
          <ion-text color="warning">mode édition</ion-text>
        </span>
      </h2>

      <ion-grid v-if="!isEditMode">
        <ion-row>
          <ion-col
            size="12"
            size-sm="6"
            size-xl="4"
            v-for="(activity, $index) in resource.activities"
            :key="$index"
          >
            <ion-button
              expand="full"
              fill="clear"
              size="large"
              @click="logActivity(activity, $event)"
              class="xs-activity-button bubbly-button"
              :class="'type-' + activity.type"
            >
              <span>{{ activity.name }}</span>
            </ion-button>
          </ion-col>
          <ion-col
            v-if="$store.state.user.displayOther"
            size="12"
            size-sm="6"
            size-xl="4"
          >
            <ion-button
              expand="full"
              fill="clear"
              size="large"
              @click="showDiversPrompt()"
              class="xs-activity-button type-1"
              ><span>Divers</span></ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>

      <Field
        v-if="isEditMode"
        name="name"
        validateOnInput
        v-slot="{ field, errorMessage, meta }"
        :rules="{ required: true, unique: names }"
        v-model="resourceName"
      >
        <ion-item>
          <ion-label position="floating">Modifier nom</ion-label>
          <ion-input
            v-bind="field"
            name="name"
            placeholder="Machine X, Personne Y, Poste de travail W, Secteur S, ..."
            v-model="resourceName"
            required
            @change="updateResourceName(meta.valid)"
          ></ion-input>
          <ion-text color="danger" slot="end" v-if="errorMessage">{{
            errorMessage
          }}</ion-text>
        </ion-item>
      </Field>

      <ion-card
        v-if="resource.activities.length === 0"
        color="warning"
        class="ion-text-center"
      >
        <ion-card-header>
          <ion-card-title>Aucune activité n'est définie.</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p>
            Définissez les activités de votre ressource pour commencer
            l'échantillonnage.
          </p>
        </ion-card-content>
      </ion-card>

      <ion-list v-if="isEditMode">
        <ion-list-header>Activités</ion-list-header>

        <ion-reorder-group
          :disabled="false"
          @ionItemReorder="doReorder($event)"
        >
          <ion-item
            v-for="(activity, $index) in resource.activities"
            class="xs-activity-button"
            :class="'type-' + activity.type"
            :key="$index"
            lines="none"
          >
            <ion-label>{{ activity.name }} </ion-label>
            <ion-button
              fill="clear"
              slot="end"
              :router-link="`/resource/${resource.id}/${$index}`"
            >
              <ion-icon slot="start" :icon="createOutline"></ion-icon>
              Modifier
            </ion-button>
            <ion-reorder slot="end"></ion-reorder>
          </ion-item>
        </ion-reorder-group>
      </ion-list>

      <ion-button v-if="isEditMode" @click="showAddActivity(resource)"
        >Ajouter une activité</ion-button
      >

      <div class="list" v-if="!$store.state.user.locked">
        <div class="item item-body">
          <ion-button
            v-if="!isEditMode && resource.activities.length > 0"
            fill="outline"
            :href="`/stats/${resource.id}`"
            >Statistiques</ion-button
          >
          <ion-button
            v-if="isEditMode"
            color="danger"
            @click="deleteResource()"
          >
            Supprimer Ressource
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { createOutline } from "ionicons/icons";

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonPage,
  IonReorder,
  IonReorderGroup,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

import { computed, defineComponent, ref, watchEffect } from "vue";
import { Field } from "vee-validate";
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import { Activity } from "@/store/models";
import {
  deleteResourceConfirm,
  showAddActivity,
  showDiversPrompt,
} from "@/services/modals";

export default defineComponent({
  name: "Resource",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonButton,
    IonButtons,
    IonText,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonInput,
    IonReorder,
    IonReorderGroup,
    IonItem,
    IonCard,
    IonList,
    IonListHeader,
    IonIcon,
    Field,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isEditMode = ref(false);

    function toggleEdit() {
      isEditMode.value = !isEditMode.value;
    }

    const resourceName = ref("");

    const resource = computed(() => {
      store.dispatch("setCurrentResourceId", route.params.id);
      return (
        store.state.resources.find((r) => r.id === route.params.id) || {
          id: "",
          name: "",
          activities: [],
        }
      );
    });

    // update localVariable with store data;
    watchEffect(() => {
      resourceName.value = resource.value.name;
    });

    function updateResourceName(valid: boolean) {
      if (valid) {
        const updatedResource = Object.assign({}, resource.value, {
          name: resourceName.value,
        });
        store.dispatch("updateResource", updatedResource);
      }
    }

    const names = computed(() => {
      return store.getters.resourceNames.filter(
        (name: string) => name !== resource.value.name
      );
    });

    function doReorder(event: CustomEvent) {
      const updatedResource = Object.assign({}, resource.value);
      updatedResource.activities = event.detail.complete(
        resource.value.activities
      );
      store.dispatch("updateResource", updatedResource);
    }

    return {
      createOutline,
      isEditMode,
      names,
      resource,
      resourceName,
      updateResourceName,
      deleteResource() {
        deleteResourceConfirm(resource.value);
      },
      toggleEdit,
      doReorder,
      logActivity(activity: Activity, event: any) {
        const button = event.target.nodeName === "ION-BUTTON" ? event.target : event.target.parentNode;
        button.classList.add('animate');
        setTimeout(() => {
          button.classList.remove('animate');
        }, 300);
        store.dispatch("logActivity", { resource: resource.value, activity });
      },
      showDiversPrompt() {
        showDiversPrompt(resource.value);
      },
      showAddActivity,
    };
  },
});
</script>
<style scoped>
.xs-activity-button {
  position: relative;
  border: 1px solid #ccc;
  font-weight: bold;
  padding-left: 20px;
  transition: transform ease-in-out 0.1s;
}

.xs-activity-button span {
  width: 100%;
  text-align: left;
}
.xs-activity-button:after {
  content: "";
  position: absolute;
  width: 20px;
  top: 0;
  left: 0;
  bottom: 0;
}
.xs-activity-button.type-0:after {
  background-color: var(--xs-type-0-color) !important;
}

.xs-activity-button.type-1:after {
  background-color: var(--xs-type-1-color) !important;
}

.xs-activity-button.type-2:after {
  background-color: var(--xs-type-2-color) !important;
}

.xs-activity-button.animate {
  transform: scale(0.9);
}
</style>
